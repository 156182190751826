import React from "react";
import styles from "./ThreeColumnInfo.module.scss";
import BackgroundPattern from "../elements/BackgroundPattern";
import FadeUp from "../effects/FadeUp";
import Section from "../layout/Section";
import Container from "../layout/Container";
import Row from "../layout/Row";
import GatsbyImage from "gatsby-image";
import generateFluid from "../../util/generateFluid";

const ThreeColumnInfo = ({items, colorsAlt, useBgPattern, centerMobile, theme}) => {
    const itemsElements = items && items.length ? items.map((item,i) => {
        return (
            <FadeUp delay={`${i * 0.15}s`} disableDelayMobile={true} className={styles.threeColumnInfo__itemsContainer__item} key={i}>
                {item.icon ? <span dangerouslySetInnerHTML={{__html: item.icon[0].contents}} className={colorsAlt ? styles.colorsAlt : ""} /> : null}
                {item.columnImage && item.columnImage.length && item.columnImage[0] ?
                    <GatsbyImage fluid={generateFluid(item.columnImage[0], "124px")} className={styles.threeColumnInfo__itemsContainer__item__image} />
                : null}
                {item.columnTitle ? <h5>{item.columnTitle}</h5> : null}
                {item.columnContent ? <span dangerouslySetInnerHTML={{__html: item.columnContent}} className={styles.threeColumnInfo__itemsContainer__item__content} /> : null}
            </FadeUp>
        );
    }) : null;

    return itemsElements ? (
        <Section className={`${styles.threeColumnInfo} ${centerMobile ? styles.threeColumnInfoCenterMobile : ""}`} theme={theme}>

            {useBgPattern ? <BackgroundPattern theme={theme} /> : null}
            
            <Container>
                <Row>
                    <div className={styles.threeColumnInfo__itemsContainer}>
                        {itemsElements}
                    </div>
                </Row>
            </Container>
        </Section>
    ) : null;
};

export default ThreeColumnInfo;