import React from "react";
import SEO from "../components/seo";
import HeroLanding from "../components/HeroLanding";
import { graphql } from "gatsby"
import Cta from "../components/Cta";
import Footer from "../components/Footer";
import ThreeColumnInfo from "../components/ThreeColumnInfo";
import LandingLeftyRighty from "../components/LandingLeftyRighty";

const primaryLandingPage = (props) => {
    const craftData = props.data.craft.entry;

    const {title, primaryLandingHero, threeColumnInfo, primaryLandingList, callToAction} = craftData;

    const heroData = primaryLandingHero ? primaryLandingHero[0] : {};
    const {displayTitle, introductionTitle, introductionContent} = heroData;

    const threeColumnInfoData = threeColumnInfo && threeColumnInfo.length ? threeColumnInfo : null

    const leftyRightyData = primaryLandingList || {};

    const ctaData = callToAction && callToAction.length ? callToAction[0] : {};
    const {ctaTitle: ctaText, primaryLink: ctaLink1, secondaryLink: ctaLink2} = ctaData;

    return (
        <>
            <SEO title={title || ""} seomatic={craftData.seomatic} />
            <HeroLanding
                pageTitle={introductionTitle || null}
                title={displayTitle || null}
                description={introductionContent || null}
                useBgPattern={true}
                theme="dark-alt"
            />
            <ThreeColumnInfo
                items={threeColumnInfoData}
                colorsAlt={true}
                useBgPattern={true}
                theme="dark-alt"
            />
            <LandingLeftyRighty
                items={leftyRightyData}
                theme="dark-alt"
            />
            <Cta text={ctaText} link1={ctaLink1} link2={ctaLink2} theme="brightscan-dark" />
            <Footer
                theme="dark"
            />
        </>
    );
}

export const pageQuery = graphql`
    query primaryLandingPage($id: CRAFT_QueryArgument!) {
        craft {
            entry(id:[$id]) {
                title
                seomatic(asArray:true) {
                    metaTitleContainer
                    metaTagContainer
                    metaLinkContainer
                    metaScriptContainer
                    metaJsonLdContainer
                }
                ...on CRAFT_pages_primaryLandingPage_Entry {
                    primaryLandingHero {
                        ...on CRAFT_primaryLandingHero_BlockType {
                            displayTitle
                            introductionTitle
                            introductionContent
                        }
                    }
                    threeColumnInfo {
                        ...on CRAFT_threeColumnInfo_BlockType {
                            icon {
                                contents
                            }
                            columnTitle
                            columnContent
                        }
                    }
                    primaryLandingList {
                        ...on CRAFT_primaryLandingList_BlockType {
                            listTitle
                            listContent
                            listLink {
                                url
                                type
                                target
                                text
                            }
                            image {
                                lqPlaceholder
                                width
                                height
                                responsiveImage(transform: "productLeftyRightyDesktopImage") {
                                    width
                                    height
                                    url
                                }
                                responsiveImageWebp: responsiveImage(transform: "productLeftyRightyDesktopImageWebp") {
                                    width
                                    height
                                    url
                                }
                            }
                        }
                    }
                    callToAction {
                        ...on CRAFT_callToAction_BlockType {
                            ctaTitle
                            primaryLink {
                                type
                                url
                                text
                                target
                            }
                            secondaryLink {
                                type
                                url
                                text
                                target
                            }
                        }
                    }

                }
            }
        }
    }

`


export default primaryLandingPage;